import { useMemo } from 'react';
import type { CSSProperties } from 'react';
import type React from 'react';
import { Button } from '@headlessui/react';
import { twMerge } from 'tailwindMerge.config';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'tertiary';
  type?: 'button' | 'submit' | 'reset';
  as?: 'button' | 'div';
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  width?: number;
  height?: number;
  children: React.ReactNode;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => void;
}

export const PtnButton: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  className = '',
  disabled = false,
  type = 'button',
  as = 'button',
  width,
  height,
  onClick,
  ...props
}) => {
  const variantStyle = useMemo(() => {
    switch (variant) {
      case 'primary':
        return 'font-bold bg-orange-20 text-gray-100 hover:bg-orange-10 data-[active]:bg-orange-5 data-[disabled]:bg-gray-60 data-[disabled]:text-gray-40';
      case 'secondary':
        return 'font-bold text-blue-20 hover:bg-gray-90 data-[disabled]:text-gray-40';
      case 'tertiary':
        return 'bg-gray-100 border border-gray-50 hover:bg-gray-90 data-[disabled]:bg-gray-60 data-[disabled]:text-gray-40';
    }
  }, [variant]);

  const handleClick = (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    if (!disabled && !!onClick) {
      onClick(e);
    }
  };

  return (
    <>
      <Button
        type={type}
        as={as}
        style={{
          ...(width ? { width: `${width}px` } : {}),
          height: `${height || 54}px`,
        }}
        className={twMerge(
          'px-2 py-1 rounded-4 box-border transition-colors cursor-pointer',
          'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-30',
          'data-[disabled]:cursor-not-allowed',
          variantStyle,
          className,
        )}
        disabled={disabled}
        onClick={handleClick}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};
